import React, { useState, useRef, useEffect, useMemo } from 'react';
import io from 'socket.io-client';

const socket = io('https://api.kapitta.com:3001', {
  secure: true,
  transports: ['websocket', 'polling'],
  path: '/socket.io'  // Nginx proxy'si ile bu path'e yönlendirme yapılacak
});

function VideoCapture() {
  const [isHost, setIsHost] = useState(null); // Ana bilgisayar olup olmadığını tutmak için
  const localVideoRef = useRef(null);  // Ana bilgisayarın kamerası
  const remoteVideoRef = useRef(null); // Diğer bilgisayardaki video akışı
  const peerConnectionRef = useRef(null);
  const servers = useMemo(() => ({
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
  }), []);

  useEffect(() => {
    if (isHost === null) return; // Eğer kullanıcı henüz seçim yapmadıysa bağlantı kurulmasın.

    const initStream = async () => {
      if (isHost) {
        // Eğer ana bilgisayarsa kamerayı al
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

        // Ana bilgisayarın videosunu göster
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }

        peerConnectionRef.current = new RTCPeerConnection(servers);

        // Ana bilgisayardaki akışı peer connection'a ekle
        stream.getTracks().forEach((track) => {
          peerConnectionRef.current.addTrack(track, stream);
        });

        peerConnectionRef.current.onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit('candidate', event.candidate);
          }
        };
      } else {
        peerConnectionRef.current = new RTCPeerConnection(servers);

        peerConnectionRef.current.onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit('candidate', event.candidate);
          }
        };

        // Diğer bilgisayarda gelen akışı göster
        peerConnectionRef.current.ontrack = (event) => {
          if (remoteVideoRef.current) {
            remoteVideoRef.current.srcObject = event.streams[0];
          }
        };
      }

      // Teklif ve cevap işlemleri
      socket.on('offer', async (offer) => {
        await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await peerConnectionRef.current.createAnswer();
        await peerConnectionRef.current.setLocalDescription(answer);
        socket.emit('answer', answer);
      });

      socket.on('answer', (answer) => {
        peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
      });

      socket.on('candidate', (candidate) => {
        peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
      });
    };

    initStream();

    return () => {
      socket.disconnect();
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }
    };
  }, [isHost, servers]);

  const createOffer = async () => {
    const offer = await peerConnectionRef.current.createOffer();
    await peerConnectionRef.current.setLocalDescription(offer);
    socket.emit('offer', offer);
  };

  // Kullanıcıya ana bilgisayar olup olmadığını soruyoruz
  if (isHost === null) {
    return (
      <div>
        <button onClick={() => setIsHost(true)}>Ana Bilgisayar (Kamerayı Paylaş)</button>
        <button onClick={() => setIsHost(false)}>Alıcı (Sadece İzle)</button>
      </div>
    );
  }

  return (
    <div>
      {isHost && (
        <div>
          {/* Ana bilgisayarın videosunu göster */}
          <video ref={localVideoRef} autoPlay playsInline muted />
          <button onClick={createOffer}>Bağlantı Kur</button>
        </div>
      )}
      {!isHost && (
        <div>
          {/* Diğer cihazın videosunu göster */}
          <video ref={remoteVideoRef} autoPlay playsInline />
        </div>
      )}
    </div>
  );
}

export default VideoCapture;
